
import { get, postJson} from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取未完成作业数量
    async getHomeworkCountByType(context, params) {
      const res = await get('/Study/teacher/getHomeworkCountByType', params)
      return res
    },
    // 根据学号和类型获取课后作业列表
    async getHomeworkListByType(context, params) {
      const res = await get('/Study/teacher/getHomeworkListByType', params)
      return res
    },
    // 根据学号和类型获取课后作业列表带筛选条件
    async getHomeworkListBySift(context, params) {
      const res = await get('/Study/teacher/getHomeworkListBySift', params)
      return res
    },
    // 单元拿题
    async getHomeworkShitiByType(context, params) {
      const res = await get('/Study/teacher/getHomeworkShitiByType', params)
      return res
    },
    // 单元拿题提交
    async submitHomework(context, params) {
      const res = await postJson('/Study/teacher/submitHomework', params)
      return res
    },
    // 错题本课后作业提交答案
    async submitWrongHomework(context, params) {
      const res = await postJson('/Study/teacher/submitWrongHomework', params)
      return res
    },
    // 获取知识点强化列表
    async getKpointHomeWorkList(context, params) {
      const res = await get('/Study/teacher/getKpointHomeWorkList', params)
      return res
    },
    // 根据id获取考点
    async getKaoDianKpointList(context, params) {
      const res = await get('/Study/kaodian/getKaoDianKpointList', params)
      return res
    },
    // 记录用户考点强化看视频时间
    async addKaoDianVideoTimeByKpoint(context, params) {
      const res = await get('/Study/kaodian/addKaoDianVideoTimeByKpoint', params)
      return res
    },
    // 考点强化拿题
    async getKaoDianQList(context, params) {
      const res = await postJson('/Study/kaodian/getKaoDianQList', params)
      return res
    },
    // 考点强化提交答案
    async submitKaoDianHomework(context, params) {
      const res = await postJson('/Study/kaodian/submitKaoDianHomework', params)
      return res
    },
    // 考点强化或者列表带状态
    async getKpointHomeWorkListByState(context, params) {
      const res = await get('/Study/teacher/getKpointHomeWorkListByState', params)
      return res
    },
    // 考点强化或者列表带状态时间
    async getKpointHomeWorkListBySift(context, params) {
      const res = await get('/Study/teacher/getKpointHomeWorkListBySift', params)
      return res
    },
    // 获取任务列表 总
    async getTaskWorkListBySift(context, params) {
      const res = await get('/Study/task/getTaskWorkListBySift', params)
      return res
    },
    // 获取时间筛选条件
    async getSiftTimeList(context, params) {
      const res = await get('/Study/teacher/getSiftTimeList', params)
      return res
    },
    // 针对训练提交提交
    async submitHomeworkNewByState(context, params) {
      const res = await postJson('/Study/teacher/submitHomeworkNewByState', params)
      return res
    },
    // 针对训练提交提交单题提交
    async submitHomeworkNewByStateNew(context, params) {
      const res = await postJson('/Study/teacher/submitHomeworkNewByStateNew', params)
      return res
    },
    // 针对训练特殊题型单词提交
    async submitSpecialWorkDanTi(context, params) {
      const res = await postJson('/Study/teacher/submitSpecialWorkDanTi', params)
      return res
    },
    // 针对训练保存进度拿题
    async getHomeworkShitiByTypeNew(context, params) {
      const res = await get('/Study/teacher/getHomeworkShitiByTypeNew', params)
      return res
    },
    
  }
}

