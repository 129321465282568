
import { get, postJson } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取错题
    async getWrongShitiList(context, params) {
      // /Study/wrongQuestion/getWrongShitiList
      // /Study/wrongQuestion/getXueGuanWrongShitiList
      const res = await get('/Study/wrongQuestion/getXueGuanWrongShitiList', params)
      return res
    },
    // 删除错题
    async delectWrongShiti(context, params) {
      const res = await get('/Study/wrongQuestion/delectWrongShiti', params)
      return res
    },
    // 恢复错题
    async updateWrongShiti(context, params) {
      const res = await get('/Study/wrongQuestion/updateWrongShiti', params)
      return res
    },
    // 举一反三拿题
    async getQListByGuidShitiId(context, params) {
      const res = await postJson('/Study/wrongQuestion/getQListByGuidShitiId', params)
      return res
    },
    // // 斩错题拿题新
    async getQListWrongShitiNew(context, params) {
      const res = await postJson('/Study/wrongQuestion/getQListWrongShitiNew', params)
      return res
    },
    // 斩错题拿题
    async getQListWrongShiti(context, params) {
      const res = await get('/Study/wrongQuestion/getQListWrongShiti', params)
      return res
    },
    // 斩错题提交答案
    async submitWrongShitiQList(context, params) {
      const res = await postJson('/Study/wrongQuestion/submitWrongShitiQList', params)
      return res
    },
    // 斩错题提交答案新
    async submitWrongShitiQListNew(context, params) {
      const res = await postJson('/Study/wrongQuestion/submitWrongShitiQListNew', params)
      return res
    },
    // 斩错题获取所有解析
    async getShitiInfoByGuidShitiId(context, params) {
      const res = await postJson('/Study/wrongQuestion/getShitiInfoByGuidShitiId', params)
      return res
    },
    // 获取单个题目解析
    async getParse(context, params) {
      const res = await get('/Study/xkwService/getPracticeShitiByGuid', params)
      return res
    },
    // 学管系统错题本根据条件获取对应错题 2025-01-02 错题本优化
    async getWrongShitiListBySift(context, params) {
      const res = await get('/Study/wrongQuestion/getWrongShitiListBySift', params)
      return res
    },
    // 获取错题本模块筛选列表
    async getWrongModuleTypeList(context, params) {
      const res = await get('/Study/wrongQuestion/getWrongModuleTypeList', params)
      return res
    },
    // 斩错题拿题
    async getQListWrongShitiAllShitiType(context, params) {
      const res = await get('/Study/wrongQuestion/getQListWrongShitiAllShitiType', params)
      return res
    },
    // 斩错题拿题提交
    async submitWrongShitiQListByCi(context, params) {
      const res = await postJson('/Study/wrongQuestion/submitWrongShitiQListByCi', params)
      return res
    }

  }
}

