
import { get, post , postJson} from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 根据sn获取正在使用的账户
    async fetchCurrentUserInfo(context, params) {
      const res = await get('/Study/xkwService/getUserChildBySn', params)
      return res
    },
    // 手机号获取所有孩子信息
    async getStudentInfoByPhone(context, params) {
      const res = await get('/Study/lazystudy/getStudentInfoByPhone', params)
      return res
    },
    // 新增孩子
    async addChildUserInfo(context, params) {
      const res = await post('/Study/xkwService/addXkwUser', params)
      return res
    },
    // 修改孩子
    async updateChildUserInfo(context, params) {
      const res = await post('/Study/xkwService/updateXkwUser', params)
      return res
    },
    // 切换正在使用的账户-记录
    async changeCurrentChild(context, params) {
      const res = await post('/Study/xkwService/insertUserChild', params)
      return res
    },
    // 修改密码
    async updatePassWord(context, params) {
      const res = await post('/Study/lazystudy/updatePassWord', params)
      return res
    },
    // 获取所有学科
    async fetchSubjectList(context, params) {
      const res = await get('/Study/xkwService/getAllSubjectList', params)
      return res
    },
    // 获取所有已选课本
    async fetchSelTextbookList(context, params) {
      const res = await post('/Study/xkwService/getXkwUserBooksH5', params)
      return res
    },
    // 根据年级科目学制拿出版社
    async fetchVersionBookList(context, params) {
      // const res = await post('/Study/xkwService/getVersionBooks', params)
      const res = await post('/Study/xkwService/getVersionByStete', params)
      return res
    },
    // 根据年级出版社拿书本
    async fetchBooksByVersion(context, params) {
      const res = await post('/Study/xkwService/getBooksByVersionByZiXiShi', params)
      return res
    },
    // 用户绑定课本
    async insertUserBook(context, params) {
      const res = await post('/Study/xkwService/insertUserBookNew', params)
      return res
    },
    // 获取学生积分信息
    async getStudentIntegration(context, params) {
      const res = await get('/Study/aiXueLian/getStudentIntegration', params)
      return res
    },
    // 获取学生学段权限
    async getStudentSiftPhasesList(context, params) {
      const res = await get('/Study/lazystudy/getStudentSiftPhasesList', params)
      return res
    }
  }
}

