
import { get } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取AI试卷总评
    async getShijuanUserInfo(context, params) {
      const res = await get('/Study/analyse/getShijuanUserInfo', params)
      return res
    },
    // 试卷分析报告 -- 获取知识点详情
    async getKpointInfoByShijuanId(context, params) {
      const res = await get('/Study/analyse/getKpointInfoByShijuanId', params)
      return res
    },
    // 试卷分析报告 -- 获取知识点详情
    async getShijuanKpointVideoTime(context, params) {
      const res = await get('/Study/analyse/getShijuanKpointVideoTime', params)
      return res
    },
    // 基础数据
    async getShijuanBasicData(context, params) {
      const res = await get('/Study/analyse/getShijuanBasicData', params)
      return res
    },
    // 获取试卷详情
    async getShijuanShitiList(context, params) {
      const res = await get('/Study/analyse/getShijuanShitiList', params)
      return res
    },
    async getShiJuanZonglanInfo(context, params) {
      const res = await get('/Study/analyse/getShiJuanZonglanInfo', params)
      return res
    },
    // 错题收集 获取作业图片坐标
    async getWorkPictureShitiInfo(context, params) {
      const res = await get('/Study/analyse/getWorkPictureShitiInfo', params)
      return res
    }
  }
}
