
import { get, postJson} from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取任务数量
    async getStudentTaskNumByState(context, params) {
      const res = await get('/Study/teacher/getStudentTaskNumByState', params)
      return res
    },
    // 获取任务数量2
    async getTaskWorkCountByType(context, params) {
      const res = await get('/Study/task/getTaskWorkCountByType', params)
      return res
    },
    // 获取学生任务列表
    async getStudentTaskList(context, params) {
      const res = await get('/Study/teacher/getStudentTaskList', params)
      return res
    },
    // 获取学生任务列表带状态时间
    async getStudentTaskListBySift(context, params) {
      const res = await get('/Study/teacher/getStudentTaskListBySift', params)
      return res
    },
    // 改变任务状态
    async updateTaskState(context, params) {
      const res = await get('/Study/teacher/updateTaskState', params)
      return res
    },
    // 任务视频
    async getVideoListByHomeworkId(context, params) {
      const res = await get('/Study/task/getVideoListByHomeworkId', params)
      return res
    },
    // 专项任务报告
    async getZhuanxiangReport(context, params) {
      const res = await get('/Study/task/getZhuanxiangReport', params)
      return res
    },
    // 保存单个视频的时间和状态
    async updataVideoStateTime(context, params) {
      const res = await get('/Study/task/updataVideoStateTime', params)
      return res
    },
    // 获取视频
    async getVideoUrlByVideoId(context, params) {
      const res = await get('/Study/tongbuyuxi/getVideoUrlByVideoId', params)
      return res
    },
    // 获取视频列表by id
    async getTongBuVideoList(context, params) {
      const res = await get('/Study/tongbuyuxi/getTongBuVideoList', params)
      return res
    },
    // 预习强化拿题
    async getKaoDianQList(context, params) {
      const res = await postJson('/Study/tongbuyuxi/getKaoDianQList', params)
      return res
    },
    // 智能讲义报告详情
    async getHandoutTaskReport(context, params) {
      const res = await get('/Study/handoutTask/getHandoutTaskReport', params)
      return res
    },
    // 讲义报告 - 环节报告详情 (测 ,学,练 , 复测)
    async getHandoutTaskLinkReport(context, params) {
      const res = await get('/Study/handoutTask/getHandoutTaskLinkReport', params)
      return res
    },
    // 讲义-学-拿全部试题
    async getHandoutTaskXueTimuShiti(context, params) {
      const res = await get('/Study/handoutTask/getHandoutTaskXueTimuShiti', params)
      return res
    },
    // 数学专项-学
    async getVideoUrlByFileId(context, params) {
      const res = await get('/Study/service/getVideoUrlByFileId', params)
      return res
    },
    // 数学专项-练
    async getZhuanXaingQList(context, params) {
      const res = await postJson('/Study/task/getZhuanXaingQList', params)
      return res
    },
    // 数学专项-提交答案
    async submitMathZhuanXiangHomework(context, params) {
      const res = await postJson('/Study/task/submitMathZhuanXiangHomework', params)
      return res
    },
    // 数学专项提交答案 2024-10-28 修改为自主判断
    async submitMathZhuanXiangNew(context, params) {
      const res = await postJson('/Study/task/submitMathZhuanXiangNew', params)
      return res
    },
    // 数学专项-报告答题
    async getMathSpecialShitiByHomeworkId(context, params) {
      const res = await get('/Study/task/getMathSpecialShitiByHomeworkId', params)
      return res
    },
    // AI测评分层测评获取题目
    async getTaskWorkShitiByTaskId(context, params) {
      const res = await get('/Study/task/getTaskWorkShitiByTaskId', params)
      return res
    },
    // AI测评分层提交接口
    async submitFenCengTaskWorkByCi(context, params) {
      const res = await postJson('/Study/task/submitFenCengTaskWorkByCiNew', params)
      return res
    },
    // 任务试卷 分次提交答案  2024-12-14 pc端  自行判断对错
    async submitTaskWorkShiJuanByCiCiNew(context, params) {
      const res = await postJson('/Study/task/submitTaskWorkShiJuanByCiCiNew', params)
      return res
    },
    // 考前冲刺获取试题
    async getPaperShitiByHomeworkId(context, params) {
      const res = await get('/Study/paper/getPaperShitiByHomeworkId', params)
      return res
    },
    // 获取教辅资料列表
    async getResourceTaskListByStudent(context, params) {
      const res = await get('/Study/resource/getResourceTaskListByStudent', params)
      return res
    },
    // 获取教辅资料任务置为完成
    async updateResourceTaskState(context, params) {
      const res = await get('/Study/resource/updateResourceTaskState', params)
      return res
    },
    
  }
}

