
import { get } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取报告日期列表
    async getWordPingDuType(context, params) {
      const res = await get('/Study/aiWordReport/getWordReportDateByCId', params)
      return res
    },
    // 获取报告概览
    async getGaiLan(context, params) {
      const res = await get('/Study/aiWordReport/getGaiLan', params)
      return res
    },
    // 学习进度
    async getZhangXuexijindu(context, params) {
      const res = await get('/Study/aiWordReport/getZhangXuexijindu', params)
      return res
    },
    // 基础数据
    async getJiChuShuJu(context, params) {
      const res = await get('/Study/aiWordReport/getJiChuShuJu', params)
      return res
    },
    // 单词掌握详情
    async getWordInfo(context, params) {
      const res = await get('/Study/aiWordReport/getWordInfo', params)
      return res
    },
    // 学习分析
    async getXueXiFenXi(context, params) {
      const res = await get('/Study/aiWordReport/getXueXiFenXi', params)
      return res
    },
    // guid获取听写任务报告
    async getTinXieTaskWorkReport(context, params) {
      const res = await get('/Study/task/getTinXieTaskWorkReport', params)
      return res
    },
    // guid获取听写任务详情
    async getTingXieReportShitiInfo(context, params) {
      const res = await get('/Study/task/getTingXieReportShitiInfo', params)
      return res
    },
   
    
  }
}
