
import { postlogin , get, post, postFormData} from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 首页登录
    async loginStudent(context, params) {
      // const res = await post('/Study/lazystudy/loginStudent', params)
      const res = await postFormData('/Study/lazystudy/loginStudentBypc', params)
      return res
    },
    // 短信登录
    async loginByPhoneAndCode(context, params) {
      const res = await postlogin('/Api/lazy/phone/loginByPhoneAndCode', params)
      return res
    },
    // 获取验证码
    async sendH5K12Code(context, params) {
      const res = await postlogin('/Api/h5/activation/sendH5K12Code', params)
      return res
    },
    // 注册
    async loginByPhoneAndCodeAndPassword(context, params) {
      const res = await postlogin('/Api/lazy/phone/loginByPhoneAndCodeAndPassword', params)
      return res
    },
    // 忘记密码
    async updatePassWordByPhone(context, params) {
      const res = await postlogin('/Api/lazy/phone/updatePassWordByPhone', params)
      return res
    },
    // 根据sn查询绑定的孩子
    async loginChild(context, params) {
      const res = await get('/Study/xkwService/getUserChildBySn', params)
      return res
    },
    // 添加一个孩子
    async AddChildfun(context, params) {
      const res = await get('/Study/xkwService/addXkwUser', params)
      return res
    },
    // 获取绑定的所有书本信息
    async getBookAll(context, params) {
      const res = await get('/Study/xkwService/getXkwUserBooksH5', params)
      return res
    },
    // 手机号获取所有孩子信息
    async getStudentInfoByPhone(context, params) {
      const res = await get('/Study/lazystudy/getStudentInfoByPhone', params)
      return res
    },
    // 课时账号激活
    async activationAccount(context, params) {
      const res = await post('/Study/lazystudy/activationAccount', params)
      return res
    },
    // 根据学号查询账号信息
    async getStudentInfoByStudentId(context, params) {
      const res = await get('/Study/lazystudy/getStudentInfoByStudentId', params)
      return res
    },
    // 推送消息 - 声网视频
    async pushAgoraNotice(context, params) {
      const res = await postlogin('https://wechat.lazyapis.com/WeChatCatWeb/ai/zxs/info/push', params)
      return res
    },
    // 学生ID查科目权限
    async getStudentSiftSubjectList(context, params) {
      const res = await get('/Study/lazystudy/getStudentSiftSubjectList', params)
      return res
    },
    // 根据手机号拿logo和名称
    async getCustomizedByPhone(context, params) {
      const res = await postlogin('https://www.lazyapis.com/Api/tools/web/page/customized/by/phone/get', params)
      return res
    },
  }
}

